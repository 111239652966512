<template>
  <b-modal centered :ref="reference" :id="id" size="xl" @hidden="$store.dispatch('removeLoginError')" hide-footer hide-header>
      <template #default="{ close }">
        <div class="d-flex">
          <div class="left d-none d-lg-flex pt-5 text-white position-relative">
            <div class="text pt-5">
              <h2 class="mx-5 px-5 mb-5">{{ $t('signup_popup.left.heading') }}</h2>
              <div class="d-flex flex-col align-items-center mx-4 points">
                <div class="d-flex ml-3">
                  <i class="fa-solid fa-check fa-lg mt-3"></i>
                  <h4 class="ml-3 mb-3 text-left">{{ $t('signup_popup.left.points.one') }}</h4>
                </div>
                <div class="d-flex ml-3">
                  <i class="fa-solid fa-check fa-lg mt-3"></i>
                  <h4 class="ml-3 mb-3 text-left">{{ $t('signup_popup.left.points.two') }}</h4>
                </div>
                <div class="d-flex ml-3">
                  <i class="fa-solid fa-check fa-lg mt-3"></i>
                  <h4 class="ml-3 mb-3 text-left">{{ $t('signup_popup.left.points.three') }}</h4>
                </div>
              </div>
            </div>

            <img class="w-100 mx-auto" v-lazy="require('../../assets/signup-popup-image.jpg')" alt="person with laptop">
          </div>
          <div class="right d-flex flex-col p-2 p-sm-3 p-lg-3">
            <div @click="close()" class="close position-absolute cursor-pointer"><i class="fa-solid fa-xmark"></i></div>
            <div class="p-2 p-lg-4 h-100 d-flex flex-col">
              <h2 class="font-weight-bold mt-4 pt-1">{{ $t('sign_in_anytasks') }}</h2>
              <div class="p-4">
                <template v-if="showETNMaintenanceError">
                  <p class="alert alert-danger p-2">
                    <i class="fa-solid fa-triangle-exclamation mx-1"></i>
                    {{ $t('login_etn_maintenance_error') }}
                    <a v-on:click="toggleModal('forgotPassword')">
                      <span><u>{{ $t('forgot_password') }}</u></span>
                    </a>
                  </p>
                </template>
                <template v-if="displayNiceErrorMessage">
                  <div v-html="$sanitize(loginError, {
                allowedTags: ['a', 'br'],
                allowedAttributes: {
                  'a': [ { name: 'href' , multiple: false, values: ['https://my.electroneum.com'] }, 'target' ]
                }
            })" class="mb-4" />
                </template>
                <template v-else-if="loginError">
                  <div class="alert alert-danger p-2 mb-1">
                    <div class="d-flex align-items-center p-1">
                      <i class="fa-solid fa-triangle-exclamation fa-lg ml-2 mr-3"></i>
                      <p class="small mb-0">
                        {{ loginError }}
                      </p>
                    </div>
                  </div>
                </template>
                <p v-else-if="!displayNiceErrorMessage && ssoError" class="alert alert-danger p-2">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{ ssoError }}
                </p>
                <template v-if="!displayNiceErrorMessage">
                  <b-form @submit.prevent="loginSubmit" v-if="show" class="pt-2">
                    <b-form-group
                      id="input-group-1"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="form.email"
                        type="email"
                        required
                        :placeholder="$t('email_placeholder')"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" label-for="input-2">
                      <b-form-input
                        id="input-2"
                        v-model="form.password"
                        type="password"
                        required
                        :placeholder="$t('password_placeholder')"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-4" class="mb-3">
                      <b-form-checkbox-group id="checkboxes-4" class="pl-3">
                        <a v-on:click="toggleModal('forgotPassword')">
                          <span class="forgot-password">{{ $t('forgot_password') }}</span>
                        </a>
                      </b-form-checkbox-group>
                    </b-form-group>
                    <b-form-group id="input-group-3" class="d-flex flex-col align-items-center">
                      <div v-if="showSpinner">
                        <b-button type="submit" class="w-100 py-2 rounded-pill" variant="primary" disabled="disabled">
                          <b-spinner class="" small label="Large Spinner"></b-spinner>
                        </b-button>
                      </div>
                      <div v-else>
                        <b-button type="submit" class="w-100 py-2 rounded-pill" variant="primary">
                          {{ $t('sign_in') }}
                        </b-button>
                      </div>
                    </b-form-group>

                  </b-form>
                  <div class="col">
                    <div class="or"><span>or</span></div>
                  </div>
                </template>
                <div class="d-flex flex-col align-items-center">
                  <div v-if="showSpinnerEtn" class="etn btn rounded-pill w-100  d-flex align-items-center justify-content-center">
                    <b-spinner class="" small label="Large Spinner"></b-spinner>
                  </div>
                  <div v-else class="etn btn rounded-pill w-100" @click="ETNUnderMaintenance ? showETNMaintenanceError = true : loginSubmitEtn()">
                    <span>{{ $t('sign_in_electroneum') }}</span>
                    <img :src="getImg('electroneum-powered.png')" :alt="$t('sign_in_electroneum_full')" class="ml-2">
                  </div>
                </div>
              </div>
              <div class="p-4 d-flex flex-col align-items-center mt-auto">
                <p v-if="!displayNiceErrorMessage" class="text-dark text-center mb-3">{{ $t('member') }}</p>
                <a v-if="!displayNiceErrorMessage" v-on:click="toggleModal('signup')" class="btn rounded-pill py-2 btn-warning">{{$t('join')}}</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import config from '../../config'

export default {
  name: 'login',
  props: {
    reason: {
      type: String,
      required: false,
      default: ''
    },
    ssoError: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: 'login'
    },
    reference: {
      type: String,
      required: false,
      default () {
        return 'my-modal'
      }
    }
  },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      show: true,
      showSpinner: false,
      showSpinnerEtn: false,
      showETNMaintenanceError: false
    }
  },
  mounted () {
    this.$root.$on('bv::modal::show', () => {
      sessionStorage.setItem('signupPopupShown', true)
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === this.id) {
        localStorage.removeItem('signUpType')
        localStorage.removeItem('signUpTypeCustom')
      }
    })
  },
  computed: {
    ...mapGetters(['hasRole']),
    ...mapState({
      loggingIn: state => state.login.loggingIn,
      loginError: state => state.login.loginError,
      loginStatus: state => state.login.loginStatus
    }),
    displayNiceErrorMessage () {
      return this.loginStatus === 451 && this.loginError !== null
    },
    ETNUnderMaintenance () {
      return config.etn_under_maintenance
    }
  },
  methods: {
    ...mapActions([
      'doLogin',
      'doLoginEtn',
      'parseJwt'
    ]),
    clearListJobLoginLocalStorage () {
      localStorage.removeItem('listJobLogIn')
    },
    getImg (pic) {
      return require('../../assets/' + pic)
    },
    loginSubmit () {
      let vm = this
      this.showSpinner = true
      this.doLogin({
        username: this.form.email,
        password: this.form.password
      }).then(() => {
        this.parseJwt().then(function (r) {
          vm.$tracker.tagEvent('userData', {
            'user': {
              'hash': r.hash,
              'email': r.email
            }
          })
          this.$tracker.tagEvent('login')
        }).catch(() => {})
        this.showSpinner = false
        if (localStorage.getItem('listJobLogIn')) {
          this.$bvModal.hide(this.id)
          this.$router.push({ name: 'task_or_job', params: { referrer: 'list_job_login' } })
          localStorage.removeItem('listJobLogIn')
          return
        }
        if (this.$store.state.accessToken !== null && (this.hasRole('ROLE_SELLER') || this.hasRole('ROLE_PRE_SELLER')) && localStorage.getItem('signUpType') !== 'purchaseTask' && localStorage.getItem('signUpTypeCustom') !== 'custom-quote') {
          this.$bvModal.hide(this.id)
          this.$router.push('/my-account/dashboard')
        } else if (this.$store.state.accessToken !== null) {
          window.location.reload()
        }
      })
    },
    loginSubmitEtn () {
      this.showSpinnerEtn = true
      this.doLoginEtn().then(() => {
        setTimeout(() => {
          this.showSpinnerEtn = false
        }, 120000)
      })
    },
    toggleModal (modal) {
      if (localStorage.getItem('signUpType') === 'purchaseTask') {
        this.$bvModal.hide(this.id)
        localStorage.setItem('signUpType', 'purchase')
      } else {
        this.$bvModal.hide(this.id)
        localStorage.setItem('signUpTypeCustom', 'custom-quote')
      }
      this.$bvModal.show(modal)
    }
  }
}

</script>

<style>
    #login .modal-body {
        padding: 0;
    }
    #forgotPassword .modal-title,
    #login .modal-title,
    #loginFavourite .modal-title,
    #loginReport .modal-title,
    #loginOrder .modal-title,
    #loginSso .modal-title,
    [id$="loginFavourite"] .modal-title,
    #loginContact .modal-title {
      width: 100%;
      text-align: center;
      font-size: 1.6em;
    }
</style>
<style scoped lang="scss">
    ::v-deep .modal-content {
    border-radius: 15px !important;
    overflow: clip;
    max-width: 1000px !important;
    margin-inline: auto;
    }

    .modal-body {
    .left {
      width: 50%;
      font-weight: bold !important;
      background: #566bb6;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .text {
        position: absolute;
        top: 0;
        h2  {
          font-size: 2.3rem;
          font-weight: bold;
        }

        .points {
          overflow-y: visible;
          h4 {
            font-size: 1.4rem;
            width: 300px;
          }
        }
      }
    }
    @include lg-up {
      .left {
        height: 730px;
      }
    }

    .right {
      width: 100%;
      overflow-y: visible;

      .crypto-asset {
        font-size: 0.85rem;
        line-height: 1.2;
        color: #999;
      }
    }
    @include lg-up {
      .right {
        width: 50%;
        height: 730px;
      }
    }
    }

    .close {
    right: 15px;
    top: 10px;
    }

    .btn, b-button {
      width: 300px !important;
    }

    #input-2{
        width: 100% !important;
    }
    span.forgot-password {
        float: right;
        color: #44aae1;
        font-weight: lighter;
        font-size: 15px;
    }

    span.footer-member {
        color: #999;
        font-weight: 300;
    }

    .text-primary {
      color: #44aae1 !important;
    }

    span.footer-join {
        color: #42b983;
    }

    span.footer-join:hover {
        text-decoration: underline;
    }

    span.forgot-password:hover {
        text-decoration: underline;
    }

    #input-group-4 {
        width: 100%;
    }

    .etn {
        background-color: #0c3547;
        color: white;
        height: 46px;
    }
    .etn:hover {
        filter: brightness(70%);
        color: white;
    }

    .etn img {
        height: 2rem;
        padding-bottom: 2px;
    }

    .col {
        width: 100%;
        padding: 0;
    }

    .col a.btn {
        width: 100%;
    }

    span hr {
        height: 1px;
        border-radius: 5%;
        border: none;
        background-color: #999;
        margin-top: 10px;
        width: 100%;
    }

    span small {
        display: block;
        text-align: center;
        color: #999;
        font-weight: 400;
    }
    .or {
        overflow: visible;
        border-bottom: 1px solid #ddd;
        text-align: center;
        height: 8px;
        margin: 18px 0 22px 0;
    }
    .or span {
        background-color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        padding: 0 12px;
        color: #404145;
        position: relative;
        top: -5px;
    }
</style>
