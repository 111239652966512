<template>
  <div class="task-info">
    <b-container>
      <div v-if="LoadPage && statsLoaded" class="row p-0">
        <breadcrumb v-if="breadcrumb.length > 0" :breadcrumbList="breadcrumb" rootClass="d-none d-lg-block mt-2" />
        <b-row class="m-0 mt-0 mt-lg-5 mb-3 w-100">
          <b-alert v-if="alreadyRequested" :show=true variant="warning" class="mt-3 mb-0 w-100 px-0 pt-2 mx-3">
            {{ alertMessage }}
          </b-alert>
          <template v-if="task.user.username === username" >
            <b-alert v-if="!active && task_status !== 3 && task_status !== 4 && $store.getters.hasRole('ROLE_SELLER')" :show=true variant="warning" class="mt-3 mb-0 w-100 px-0 pt-0 mx-3">
              <p class="alert mb-0 pb-0" v-if="updatedAlert">
                <i class="fa-solid fa-triangle-exclamation fa-2x text-warning align-middle pr-2"></i>
                {{ $t('success_task_updated') }}
              </p>
              <p class="alert mb-0 pb-0" v-else>
                <i class="fa-solid fa-triangle-exclamation fa-2x text-warning align-middle pr-2"></i>
                {{ $t('awaiting_activation') }}
              </p>
            </b-alert>
            <b-alert v-if="!$store.getters.getVerification() && $store.getters.hasRole('ROLE_PRE_SELLER')" :show=true variant="warning" class="mt-3 mb-0 w-100 px-0 pt-0 mx-3">
              <p class="alert mb-0 pb-0" >
                {{ $t('verification_needed_to_activate_tasks') }}
                <router-link :to="{ name: 'connecting-etn' }">{{ $t('verification_banner.cta') }}</router-link>
              </p>
            </b-alert>
            <b-alert v-if="updatedAlert && task_status === 2" :show=true variant="warning" class="mt-3 mb-0 w-100 mx-3">
              <p class="alert mb-0 pb-0" v-if="updatedAlert">
                <i class="fa-solid fa-triangle-exclamation fa-2x text-warning align-middle pr-2"></i>
                {{ $t('success_task_updated') }}
              </p>
            </b-alert>
            <b-alert v-if="task_status === 3" :show=true variant="danger" class="mt-3 mb-0 w-100 mx-3">
              <i class="fa-solid fa-circle-xmark fa-2x align-middle text-danger pr-2"></i>
              {{$t('task_declined')}} {{task.decisions[0].reason}}
            </b-alert>
            <b-alert v-if="task_status === 4" :show=true variant="warning" class="mt-4 mt-md-0 mx-3 w-100 text-left d-flex flex-column flex-md-row justify-content-between align-items-center">
            <p class="mb-0">
              <i class="fa-solid fa-triangle-exclamation fa-2x text-warning align-middle pr-2"></i>
              {{ $t('task_status_4_warning') }}
            </p>
            <div class="text-right mt-2 mt-md-0 ml-0 ml-md-3 w-100 w-md-auto">
              <a :href="myEtnLink" target="_blank" rel="noopener" class="btn btn-primary w-100 w-md-auto mb-2 mb-md-0">{{ $t('verification_banner.cta') }}</a>
            </div>
          </b-alert>
          </template>
          <b-alert v-if="errorMessageSwitch" :show=true variant="warning" class="mt-3 mb-0 w-100 mx-3">
            {{errorMessage}}
          </b-alert>
          <b-alert  v-if="$store.getters.hasRole('ROLE_ADMIN') && task.user.verification_type === 2" variant="warning" :show="true" class="mt-3 mb-0 w-100 mx-3">
            <i class="fa-solid fa-triangle-exclamation"></i> This Seller is awaiting verification, you will not be able to purchase this Task
          </b-alert>

          <div v-if="task.user.username === username" class="pb-0 w-100 pt-3 mx-3 d-block d-lg-none">
            <span class="pull-left w-50 pb-2 px-1" v-if="$store.getters.getVerification() === 1 && task.status !== 4 && $store.getters.hasRole('ROLE_SELLER')">
              <b-button class="btn-success" v-on:click="confirmActivateTask()" size="sm" block>{{ active ? $t('deactivate') : $t('submit_review') }}</b-button>
            </span>
            <span class="pull-right pb-2 px-1" v-bind:class="{'w-100': $store.getters.getVerification() === 0 || task.status === 4 || !$store.getters.hasRole('ROLE_SELLER'), 'w-50': $store.getters.getVerification() === 1 && task.status !== 4 }">
              <b-button @click="$router.push({ name: 'editTask', params: {slug: task.hash}})" variant="primary" size="sm" block>{{ $t('edit') }}</b-button>
            </span>
          </div>
          <b-alert :show=true variant="warning" class="mt-3 mb-0 w-100 mx-3" v-if="task.user.username !== username && away">
            {{awayText}}
          </b-alert>

          <!-- Left Column -->
          <div class="cols-12 col-lg-8 pb-3 pb-md-0">
            <div class="main-container pb-0 pt-3">
              <div class="container p-0">
                <!-- Block One -->
                <div class="card mb-0 mb-md-3 p-3">
                  <!-- Title -->
                  <div class="title text-left">
                    <h1 class="task-heading p-2 font-weight-bold mb-3">{{task.title}}</h1>
                  </div>
                  <div class="task-top-info d-flex justify-content-between py-2 px-2">
                    <div class="d-flex flex-row">
                      <ObjectLabel v-if="task.hero" type="hero" :object="{}" class="mr-2"/>
                      <!-- Rating -->
                      <div v-if="task.review_average > 0" class="ml-2 p-0 rating">
                        <ul class="p-0 m-0">
                          <li>
                            <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 0.5), 'fa-star' : ( 0.5 <= task.review_average )}" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 1.5), 'fa-star' : ( 1.5 <= task.review_average )}" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 2.5), 'fa-star' : ( 2.5 <= task.review_average )}" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 3.5), 'fa-star' : ( 3.5 <= task.review_average )}" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 4.5), 'fa-star' : ( 4.5 <= task.review_average )}" aria-hidden="true"></i>
                            <strong> {{ task.review_average.toFixed(2) }} </strong>
                            <p class="d-none d-sm-inline-block m-0">({{ task.review_count.toLocaleString() }} {{$t('task_reviews')}})</p>
                          </li>
                        </ul>
                      </div>
                      <ObjectLabel v-else type="new" :object="this.task"></ObjectLabel>
                    </div>
                    <!-- Links -->
                    <div class="p-0 social-links" v-if="!isJob">
                      <!-- Save -->
                      <span class="fa-solid fa-heart cursor" v-bind:class="{ 'save-toggle' : task.favourite }" v-b-popover.hover.top="popUpText" v-on:click="toggle" />
                      <!-- Social Share -->
                      <a href="#" v-b-popover.hover.top="$t('share_heading')" class="fa-solid fa-share-nodes p-0" id="popover-3" onclick="return false"></a>
                      <b-popover target="popover-3" triggers="focus" placement="bottom">
                        <template slot="title">{{ $t('share_task') }}</template>
                        <ShareNetwork
                          network="facebook"
                          :url="route"
                          :title="this.task.title"
                          :description="this.task.description"
                          hashtags="LovingYourWork,AnyTask"
                        >
                          <i class="fa-brands fa-facebook-square fa-lg ml-3" style=" color: #3b5998; cursor: pointer;" />
                        </ShareNetwork>
                        <ShareNetwork
                          network="linkedin"
                          :url="route"
                          :title="this.task.title"
                          :description="this.task.description"
                          hashtags="LovingYourWork,AnyTask"
                        >
                          <i class="fa-brands fa-linkedin fa-lg ml-3 " style="color: #0976b4; cursor: pointer;" />
                        </ShareNetwork>
                        <ShareNetwork
                          network="twitter"
                          :url="route"
                          :title="this.task.title"
                          :description="this.task.description"
                          hashtags="LovingYourWork,AnyTask"
                        >
                          <i class="fa-brands fa-square-x-twitter fa-lg ml-3" style="color: black; cursor: pointer;" />
                        </ShareNetwork>
                        <ShareNetwork
                          network="whatsapp"
                          :url="route"
                          :title="this.task.title"
                          :description="this.task.description"
                          hashtags="LovingYourWork,AnyTask"
                        >
                          <i class="fa-brands fa-whatsapp fa-lg ml-3" style="color: #25d366; cursor: pointer;" />
                        </ShareNetwork>
                        <ShareNetwork
                          network="reddit"
                          :url="route"
                          :title="this.task.title"
                          :description="this.task.description"
                          hashtags="LovingYourWork,AnyTask"
                        >
                          <i class="fa-brands fa-reddit fa-lg ml-3" style="color: #ff5700; cursor: pointer;" />
                        </ShareNetwork>
                      </b-popover>
                      <!-- Report -->
                      <a v-on:click="report" v-b-popover.hover.top="$t('report_type_task')" class="fa-solid fa-flag p-0" target="_self"></a>
                    </div>
                  </div>
                  <!-- Carousel -->
                  <Carousel :images="getImagesFromAssets(task)" :taskHash="task.hash" class="carousel-holder" placeholder-image="https://images.anytask.com/image/upload/c_scale,h_425,w_680/c_scale,e_blackwhite,l_urbiknsbdw7r5mpntg5w,o_35,w_150/v1580733311/uerbsfwhzr3lsgstyv0h.png"/>
                  <!-- Rating and Links -->
                  <div class="pt-2" align="left">
                    <!-- Task Description -->
                    <small class="font-italic">{{$t('task_maybe_translated')}}</small>
                    <div class="description">
                      <h3 class="p-2 font-weight-bold mb-3">{{ $t('description') }}</h3>
                      <p class="mt-4" v-html="this.$sanitize(task.description)"/>
                    </div>
                    <div class="you-will-get">
                      <h3 class="p-2 font-weight-bold mb-3">{{ $t('package_explanation') }}</h3>
                      <p class="p-0 m-0" v-html="this.$sanitize(task.packages[0].description)"/>
                    </div>
                    <div v-if="!isCustomTask && !isJob">
                      <!-- Purchase Button -->
                      <div v-if="loading" class="d-none d-md-block">
                        <b-button type="submit" class="w-100 task-purchase btn mt-4 mb-0" variant="primary" disabled="disabled">
                          <b-spinner small label="Large Spinner" />
                        </b-button>
                      </div>
                      <div v-else-if="displayPurchase" class="flex-row">
                        <div :class="task.user.band ? 'col-md-12' : 'col-md-6'" class="pl-0 pr-2">
                          <b-button v-on:click="taskBasket()" class="w-100 task-purchase btn mt-4 mb-0 d-none d-md-block" variant="primary">
                            {{$t('purchase')}}
                          </b-button>
                        </div>
                        <div class="col-md-6 pr-0 pl-2">
                          <b-button v-if="!task.user.band" :disabled="alreadyRequested" v-on:click="customTaskBasket()"  class="w-100 custom-quote etn btn mt-4 mb-0 d-none d-md-block">
                            {{ $t('get_custom_quote') }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Block Four - Reviews - Large Screen Only -->
              <div class="p-0 mt-3 mb-3 d-none d-lg-block" id="reviews" v-if="task.reviews.length > 0 ">
                <Reviews
                  :average-rating=task.review_average.toFixed(2)
                  :review-count=task.review_count
                  :reviews=task.reviews
                  :reviewsTitle="$t('task_reviews')"
                />
              </div>
            </div>
          </div>

          <!-- Right Column -->
          <div class="cols-12 col-lg-4 pb-3 pt-0 pt-sm-0 pt-md-3">
            <div v-if="this.$route.params.username === username && !isJob" class="pb-0 d-none d-lg-block">
                <small class="pr-2">{{ $t('status') }}:</small>
                <span v-if="task_status === 1" class="badge badge-primary mb-3 text-wrap text-white">{{ $t('active') }}</span>
                <span v-else-if="task_status === 2" class="badge badge-warning mb-3 text-wrap">{{ $t('pending_approval') }}</span>
                <span v-else-if="task_status === 3" class="badge badge-danger mb-3 text-wrap text-white">{{ $t('declined_button') }}</span>
                <span v-else-if="task.status === 4" class="badge badge-dark mb-3 text-wrap ">{{ $t('unlisted') }}</span>
                <span v-else class="badge badge-secondary mb-3 text-wrap text-white">{{ $t('draft') }}</span>
              <br />
              <span class="pull-left w-50 pb-2 px-1" v-if="$store.state.user.verification === 1 && task.status !== 4 && $store.getters.hasRole('ROLE_SELLER')">
                  <b-button class="btn-success" v-on:click="confirmActivateTask()" size="sm" block>{{ active ? $t('deactivate') : $t('submit_review') }}</b-button>
              </span>
              <span class="pull-right pb-2 px-1" v-bind:class="{'w-100': $store.state.user.verification === 0 || task.status === 4 || !$store.getters.hasRole('ROLE_SELLER'), 'w-50': $store.state.user.verification === 1 && task.status !== 4}">
                  <b-button @click="$router.push({ name: 'editTask', params: { slug: task.hash }})" variant="primary" size="sm" block>{{ $t('edit') }}</b-button>
              </span>
            </div>

            <!-- Summary -->
            <div class="sidebar-summary mb-3">
              <div class="card">
                <h3 class="p-2 font-weight-bold mb-3">{{ $t('summary_lower') }}</h3>
                <div class="d-flex flex-column summary-info">
                  <div class="delivery d-flex justify-content-start align-items-center stat mb-2 pb-2">
                    <div class="icon d-flex justify-content-center align-items-center"><i class="fa-regular fa-clock"></i></div>
                    <div class="font-weight-bold mx-3 amount">{{task.packages[0].delivery/24}}</div>
                    <div>{{ $t('max_days_before_delivery') }}</div>
                  </div>
                  <div class="revisions d-flex justify-content-start align-items-center stat mb-2 pb-2">
                    <div class="icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-arrows-rotate"></i></div>
                    <div class="font-weight-bold mx-3 amount">{{task.packages[0].revisions}}</div>
                    <div>{{ $t('revisions_included') }}</div>
                  </div>
                  <div class="languages d-flex justify-content-start align-items-center stat">
                    <div class="icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-globe"></i></div>
                    <div class="font-weight-bold mx-3 amount">{{task.languages.length}}</div>
                    <div>{{ $t('language_header_for_task') }}</div>
                  </div>
                  <div>
                    <div v-for="(lang, index) in task.languages" :key="index" class="language-available text-left">{{ lang.name_local }}</div>
                  </div>
                </div>
                <div v-if="!isCustomTask && !isJob">
                  <div class="d-flex mt-4 mb-2 align-items-center justify-content-between">
                    <h4 class="font-weight-bold">{{ $t('quantity_heading') }}</h4>
                    <div class="d-flex align-items-center">
                      <i v-if="!quantityMinReached && quantitySelected !== 1" class="fa-solid fa-circle-minus quantity-button quantity-minus cursor-pointer" aria-hidden="true" @click="quantitySelected = quantitySelected > 1 ? (quantitySelected - 1) : quantitySelected"></i>
                      <i v-else class="fa-solid fa-circle-minus quantity-button quantity-minus cursor-pointer" style="color: #cccccc" aria-hidden="true"></i>
                      <b-form-input v-model="quantitySelected" @focusout="checkValidQuantity" class="px-1 text-center mx-2" min="1" :max="maxQuantity" type="number" size="sm"/>
                      <i v-if="!quantityMaxReached && quantitySelected !== maxQuantity" class="fa-solid fa-circle-plus quantity-button quantity-plus cursor-pointer" aria-hidden="true" @click="quantityAdd()"></i>
                      <i v-else class="fa-solid fa-circle-plus quantity-button quantity-plus cursor-pointer" aria-hidden="true" style="color: #cccccc"></i>
                    </div>
                  </div>
                  <div v-if="!quantityErrorMessageSwitch" class="d-flex mb-3 align-items-center justify-content-between">
                    <h4 class="font-weight-bold">{{ $t('price_heading') }}</h4>
                    <h4 class="font-weight-bold">{{ getDisplayPrice(getCalculatedPrice)}}</h4>
                  </div>
                  <b-alert v-if="quantityErrorMessageSwitch" class="mt-3" show variant="danger">{{ quantityErrorMessage }}</b-alert>
                  <!-- Purchase Button -->
                  <b-button v-else-if="loading" type="submit" class="w-100 task-purchase btn mt-3 mb-0" variant="primary" disabled="disabled">
                    <b-spinner small label="Large Spinner" />
                  </b-button>
                  <div v-else-if="displayPurchase">
                    <b-button v-on:click="taskBasket()" class="w-100 task-purchase btn mt-3 mb-0 d-none d-md-block" variant="primary">
                      {{$t('purchase')}}
                    </b-button>
                    <b-button v-if="!task.user.band" :disabled="alreadyRequested" v-on:click="customTaskBasket()" class="w-100 custom-quote etn btn mt-3 mb-0 d-none d-md-block">
                      {{ $t('get_custom_quote') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar-summary p-0 mt-3 mb-3">
              <div class="card">
                <h3 class="p-2 font-weight-bold mb-3">{{ $t('task_info_protection.title') }}</h3>
                <div class="summary-info">
                  <p>{{ $t('task_info_protection.p1') }}</p>
                  <p>{{ $t('task_info_protection.p2') }}</p>
                  <p v-html="$t('task_info_protection.p3', { 'link_tc': 'https://anytask.com/terms-and-conditions', 'link_ap': 'https://anytask.com/anytask-protection' })"></p>
                </div>
              </div>
            </div>
            <div class="sidebar-profile">

              <!-- User Profile -->
              <UserTaskProfile class="mb-3"
                :username="task.user.username"
                :verify="task.user.verification_type"
                :rating="task.user.review_average"
                :reviews="task.user.review_count"
                :online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
                :lastActive="task.user.last_active"
                :shortDescription="task.user.summary"
                :buttons="true"
                :image_path="task.user.photo_path"
                :hash="task.user.hash"
                :country="task.user.country_iso"
                :sellerResponse="this.sellerResponse"
                :sellerDelivery="this.sellerDelivery"
                :sellerAcceptance="this.sellerAcceptance"
                :sellerQueue="this.sellerQueue"
                :disputeQueue="this.disputeQueue"
                :disputeAgainst="this.disputeAgainst"
                :admin="this.admin"
                :task="this.task"
                :loadPage="this.LoadPage"
                :join="($t('month.' + (joinedDate.getMonth() + 1))) + ' ' + joinedDate.getFullYear()"
              popover_id="taskProfile"/>

              <!-- Reviews - Tablet and Smaller -->
              <div class="container p-0 mt-3 d-block d-lg-none"  v-if="task.reviews.length > 0 ">
                <Reviews
                  :average-rating=task.user.review_average.toFixed(2)
                  :review-count=task.user.review_count
                  :reviews=task.reviews
                  :amount-to-show="2"
                  :reviewsTitle="$t('task_reviews')"
                />
              </div>
            </div>

            <div class="d-sm-block d-md-none position-fixed buy-cta-mobile" v-if="displayPurchase && !quantityErrorMessageSwitch">
              <div class="d-flex px-3 pt-2 pb-3 justify-content-center">
                <b-button v-if="loading" type="submit" class="w-100 task-purchase btn mt-4 mb-0" variant="primary" disabled="disabled">
                  <b-spinner small label="Large Spinner" />
                </b-button>
                <div v-else class="flex-column w-100">
                  <div class="d-flex justify-content-center">
                    <div class="mb-1 price-mobile mr-2">{{ $t('quantity_heading') }}: <strong>{{ quantitySelected }}</strong></div>
                    <div class="mb-1 price-mobile">{{ $t('price_heading') }}: <strong>{{ getDisplayPrice(getCalculatedPrice)}}</strong></div>
                  </div>
                  <b-button v-on:click="taskBasket()" class="w-100 task-purchase btn m-0" variant="primary">{{$t('purchase')}}</b-button>
                  <b-button v-if="!task.user.band" :disabled="alreadyRequested" v-on:click="customTaskBasket()" class="w-100 custom-quote etn btn mt-3 mb-0">
                    {{ $t('get_custom_quote') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>

        </b-row>
      </div>
      <div v-else>
        <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
        <div id="loader-quip-tasks" class="mb-5">
          {{ loadingSwitch ? $t('loading') : $t('loading_task_info') }}
        </div>
      </div>
    </b-container>
    <TaskShowcase v-if="isLoggedIn && LoadPage && statsLoaded" location="taskPage" class="mb-5"/>
    <LoginModal :reason="$t('login_reason_favourite')" id="loginFavourite"/>
    <LoginModal :reason="$t('login_reason_report')" id="loginReport"/>
    <Report :type="$t('task')" refId="modal-report-task" :options="$t('task_report_reasons')" :hash="task.hash"/>

    <b-modal id="custom-quote" :title="this.customText + this.customTaskTitle" hide-footer class="p-0 p-md-4">
      <CustomTaskForm
        :task="task"
      />
    </b-modal>
  </div>
</template>

<script>
// If the layout changes on this page will need to change TaskPreview.vue as well
import UserTaskProfile from '../../components/Task/UserTaskProfile'
import Carousel from '../../components/Global/CardCarousel'
import Reviews from '../../components/Task/Reviews'
import ApiService from '../../apiService'
import LoginModal from '../../components/Global/Login'
import Report from '../../components/Task/Report.vue'
import config from '../../config'
import helper from '../../helper'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import ObjectLabel from '../../components/Global/Label'
import CustomTaskForm from '../../components/CustomTask/CustomTaskForm'
import { mapState } from 'vuex'
import store from '../../store'
import TaskShowcase from '../../components/Global/TaskShowcase.vue'

export default {
  name: 'TaskInfo',
  components: { ObjectLabel, Carousel, UserTaskProfile, Reviews, LoginModal, Report, Breadcrumb, CustomTaskForm, TaskShowcase },
  props: {
    activateEdit: {
      type: String,
      required: false
    }
  },
  metaInfo () {
    return {
      link: [
        {
          rel: 'canonical',
          href: this.task.user ? window.location.protocol + '//' + window.location.host + '/' + this.task.user.username + '/' + this.task.slug : ''
        }
      ],
      title: [
        this.task.user ? this.task.title + ' - AnyTask.com' : ''
      ],
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.task.user ? this.task.title + ' - AnyTask.com' : ''
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: ('assets' in this.task && this.task.assets.length > 0) ? this.task.assets[0].path : ''
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.task.description && (this.task.description.length > 100 ? this.task.description.replace(/(<\/p><p>)/ig, ' ').replace(/(<([^>]+)>)/ig, '').substring(0, 100) + '...' : this.task.description.replace(/(<\/p><p>)/ig, ' ').replace(/(<([^>]+)>)/ig, ''))
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: this.task.user ? window.location.protocol + '//' + window.location.host + '/' + this.task.user.username + '/' + this.task.slug : ''
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'article'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ],
      script: [
        this.LdJson
      ]
    }
  },
  data () {
    return {
      settings: JSON.parse(sessionStorage.getItem('settings')),
      anyTasksName: config.anytasks_name,
      route: window.location.toString(),
      username: localStorage.getItem('username'),
      slug: this.$route.params.slug,
      loadingSwitch: localStorage.getItem('signUpType') === 'purchaseTask',
      task: [],
      active: true,
      loading: false,
      selected: false,
      popUpText: '',
      joinedDate: null,
      statsLoaded: false,
      LoadPage: false,
      task_status: 0,
      updatedAlert: false,
      updatedAlertHash: this.activateEdit,
      tracking_switch: config.tracking_enabled === 'true',
      breadcrumb: [],
      selectedCategory: null,
      away: false,
      awayText: '',
      customText: '[CUSTOM] ',
      customTaskTitle: '',
      isCustomTask: false,
      alreadyRequested: false,
      alertMessage: '',
      errorMessage: '',
      errorMessageSwitch: false,
      quantityErrorMessage: '',
      quantityErrorMessageSwitch: false,
      admin: false,
      disputeAgainst: 0,
      assetsCounter: [
        0,
        0
      ],
      disputeQueue: 0,
      LdJson: {},
      myEtnLink: config.my_etn,
      quantitySelected: 1,
      quantityMaxReached: false,
      quantityMinReached: true,
      maxQuantity: null,
      minQuantity: 1
    }
  },
  mounted () {
    helper.loadQuip('loader-quip-tasks')
    this.getSellerStats()
    this.getTaskBySlug()
    this.getTaskSaved()
    this.textToggle()
    if (this.updatedAlertHash === '#edit') {
      this.updatedAlert = true
    }
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    customCheck () {
      this.loading = true
      ApiService.getCustomTaskCheck(this.task.hash).then((resp) => {
        if (this.username !== this.task.user.username) {
          this.$bvModal.show('custom-quote')
          this.googleTag('eec.add')

          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'customQuoteRequest'
            })
          }
        }
        this.loading = false
      }).catch((error) => {
        this.loading = false
        this.alreadyRequested = true
        this.alertMessage = error.response.data.error_message
        this.scrollToTop()
      })
    },
    changeSelected (value) {
      this.selected = value
    },
    toggle () {
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show('loginFavourite')
      } else {
        if (this.selected) {
          this.$swal({
            title: this.$t('remove_task_title'),
            text: this.$t('remove_task_text'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('remove_task_confirm'),
            cancelButtonText: this.$t('remove_task_cancel')
          }).then((result) => {
            if (result.value === true) {
              this.selected = false
              this.textToggle()
              return ApiService.removeFavouriteTask(this.task.hash).then(() => {
              }).catch(() => {
                this.selected = true
                this.textToggle()
                this.$swal(this.$t('remove_task_error_title'), this.$t('remove_task_error_text'), 'error')
              })
            }
          })
        } else {
          ApiService.addFavouriteTask(this.task.hash).then((data) => {
            this.selected = true
            this.textToggle()
          }).catch(() => { })
        }
      }
    },
    report () {
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show('loginReport')
      } else {
        this.$bvModal.show('modal-report-task')
      }
    },
    getTaskSaved () {
      // TODO: check task to see if it is saved/liked, then assign it to selected. Return false if not logged in
    },
    textToggle () {
      if (this.selected) {
        this.task.favourite = true
        this.popUpText = this.$t('favourite_task_unselect')
      } else {
        this.task.favourite = false
        this.popUpText = this.$t('favourite_task_select')
      }
    },
    getImg (pic) {
      return require('../../assets/' + pic)
    },
    getTaskBySlug () {
      ApiService.getTaskByUser(this.$route.params.username, this.$route.params.slug).then(response => {
        this.task = response.data.data[0]
        this.maxQuantity = 500 / this.task.packages[0].price <= 20 ? 500 / this.task.packages[0].price : 20
        if (this.task.custom !== null) {
          this.isCustomTask = true
        }
        this.joinedDate = new Date(this.task.user.member_since)
        this.active = this.task.status === 1 || this.task.status === 2
        this.task_status = this.task.status
        helper.reorderMetas()

        const category = window.sessionStorage.getItem('task_category')
        this.selectedCategory = this.task.categories[0]

        for (let i = 0; i < this.task.categories.length; i++) {
          if (this.task.categories[i].slug === category) {
            this.selectedCategory = this.task.categories[i]
            break
          }
        }

        let returnDate = this.task.user.returning_date !== null ? new Date(this.task.user.returning_date) : null
        let today = new Date()
        if (this.task.user.away && !returnDate) {
          this.away = true
          this.awayText = this.$t('away_notice.no_date')
        } else if (this.task.user.away && returnDate > today) {
          this.away = true
          this.awayText = this.$t('away_notice.date', { 'date': returnDate.toLocaleDateString() })
        }

        if (localStorage.getItem('signUpType') === 'purchaseTask') {
          localStorage.removeItem('signUpType')
          this.taskBasket()
        } else if (localStorage.getItem('signUpTypeCustom') === 'custom-quote') {
          localStorage.removeItem('signUpType')
          this.customTaskBasket()
        } else {
          this.LoadPage = true
        }

        this.createLdJson(this.task)
        this.breadcrumb = [
          { 'name': this.anyTasksName, 'link': this.$router.resolve({ name: 'home' }).href },
          { 'name': this.selectedCategory.parent_category.name, 'link': this.$router.resolve({ name: 'category', params: { slug: this.selectedCategory.parent_category.slug } }).href },
          { 'name': this.selectedCategory.name, 'link': this.$router.resolve({ name: 'search_category', params: { slug: this.selectedCategory.parent_category.slug, child: this.selectedCategory.slug.split('/')[1] } }).href },
          { 'name': this.task.title, 'link': this.$router.resolve({ name: 'task_info', params: { username: this.task.user.username, slug: this.task.slug } }).href }
        ]
      }).catch(() => { })
    },
    createLdJson (task) {
      let primaryImg = ''
      if (task.assets.length > 0) {
        primaryImg = task.assets[0].type === 2 ? task.assets[0].path.replace(/\.([a-z0-9]{3,5})$/, '.jpg') : task.assets[0].path
      }
      const ld = {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Product',
          'image': primaryImg,
          'name': task.title,
          'offers': {
            '@type': 'Offer',
            'url': window.location.protocol + '//' + window.location.host + '/' + task.user.username + '/' + task.slug,
            'priceCurrency': 'USD',
            'price': this.settings.service_rate ? (task.packages[0].price + (task.packages[0].price * this.settings.service_rate / 100)) : task.packages[0].price
          }
        }
      }
      if (task.review_count > 0) {
        ld.json['aggregateRating'] =
            {
              '@type': 'AggregateRating',
              'ratingValue': task.review_average,
              'ratingCount': task.review_count
            }
      }
      this.LdJson = ld
    },
    googleTag (event) {
      if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        if (event === 'eec.detail') {
          let vm = this
          this.task.assets.forEach(function (item) {
            vm.assetsCounter[(item.type - 1)]++
          })
          store.commit('setTrackingData', {
            'user_review_count': this.task.user.review_count,
            'user_review_average': this.task.user.review_average,
            'seller_response': this.sellerResponse,
            'seller_acceptance': this.sellerAcceptance,
            'seller_delivery': this.sellerDelivery === null ? 0 : this.sellerDelivery,
            'parent_category': this.task.categories[0].parent_category.slug,
            'sub_category': this.task.categories[0].slug,
            'brand': this.task.user.hash,
            'image': this.assetsCounter[0],
            'review_count': this.task.review_count,
            'review_average': this.task.review_average,
            'video': this.assetsCounter[1],
            'seller_iso': this.task.user.country_iso,
            'seller_date': this.task.user.member_since
          })
        }

        if (event === 'eec.detail') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'eec.detail',
            'ecommerce': {
              'products': [{
                'price': this.task.packages[0].price,
                'item_id': this.task.hash,
                'item_name': this.task.title,
                'category': this.task.categories[0].parent_category.slug + '/' + this.task.categories[0].slug,
                'brand': this.task.user.hash,
                'country_iso': this.task.user.country_iso,
                'member_since': this.task.user.member_since,
                'task_review_count': this.task.review_count,
                'task_review_average': this.task.review_average,
                'user_review_count': this.task.user.review_count,
                'user_review_average': this.task.user.review_average,
                'seller_response': this.sellerResponse,
                'seller_acceptance': this.sellerAcceptance,
                'seller_delivery': this.sellerDelivery === null ? 0 : this.sellerDelivery
              }]
            }
          })
          if (this.$route.params.queryID === 'suggested') {
            window.dataLayer.push({
              'event': 'suggestedTaskClick',
              'ecommerce': {
                'currencyCode': 'USD',
                'products': [{
                  'item_name': this.task.title,
                  'item_id': this.task.hash,
                  'price': this.task.packages[0].price,
                  'task_review_count': this.task.review_count,
                  'task_review_average': this.task.review_average
                }]
              }
            })
          }
        } else if (event === 'eec.add') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'eec.add',
            'ecommerce': {
              'products': [{
                'price': this.task.packages[0].price,
                'item_id': this.task.hash,
                'item_name': this.task.title,
                'category': this.task.categories[0].parent_category.slug + '/' + this.task.categories[0].slug,
                'brand': this.task.user.hash,
                'country_iso': this.task.user.country_iso,
                'member_since': this.task.user.member_since,
                'task_review_count': this.task.review_count,
                'task_review_average': this.task.review_average,
                'user_review_count': this.task.user.review_count,
                'user_review_average': this.task.user.review_average,
                'seller_response': this.sellerResponse,
                'seller_acceptance': this.sellerAcceptance,
                'seller_delivery': this.sellerDelivery === null ? 0 : this.sellerDelivery
              }]
            }
          })
        }
      }
    },
    getSellerStats () {
      ApiService.getSellerStats(this.$route.params.username, this.$route.params.slug).then(response => {
        this.stat = response.data.data
        if (this.stat.task_response_rate !== null) {
          this.sellerResponse = this.stat.task_response_rate.toFixed()
        } else {
          this.sellerResponse = this.stat.task_response_rate
        }
        if (this.stat.task_delivery_on_time_rate !== null) {
          this.sellerDelivery = this.stat.task_delivery_on_time_rate.toFixed()
        } else {
          this.sellerDelivery = this.stat.task_delivery_on_time_rate
        }
        if (this.stat.task_acceptance !== null) {
          this.sellerAcceptance = this.stat.task_acceptance.toFixed()
        } else {
          this.sellerAcceptance = this.stat.task_acceptance
        }
        this.sellerQueue = this.stat.seller_queue

        this.admin = this.$store.getters.hasRole('ROLE_ADMIN_TASK')
        if (this.admin) {
          this.sellerQueue = this.stat.seller_disputes_queue
          this.disputeAgainst = this.stat.seller_disputes_result_went_against
        }
        this.statsLoaded = true
      }).catch(() => { })
    },
    getImagesFromAssets (media) {
      let images = []

      media.assets.forEach(function (asset) {
        let img = {}
        img['type'] = asset.type

        if (asset.type === 2) {
          // Movie
          img['src'] = helper.formatCloudinaryUrl(asset.path, 'movie_large')
          img['mp4'] = helper.formatCloudinaryUrl(asset.path, 'movie_mp4_large')
        } else {
          // Image
          img['src'] = helper.formatCloudinaryUrl(asset.path, 'task_large')
        }
        images.push(img)
      })
      if (images.length === 0) {
        images.push('https://images.anytask.com/image/upload/c_scale,h_220,w_330/c_scale,e_blackwhite,l_urbiknsbdw7r5mpntg5w,o_35,w_150/v1580733311/uerbsfwhzr3lsgstyv0h.png')
      }
      return images
    },
    customTaskBasket () {
      this.customTaskTitle = this.task.title
      if (this.$store.state.accessToken === null) {
        localStorage.setItem('signUpTypeCustom', 'custom-quote')
        this.$bvModal.show('signup')
      } else {
        this.customCheck()
        this.LoadPage = true
        localStorage.removeItem('signUpTypeCustom')
      }
    },
    taskBasket () {
      if (this.$store.state.accessToken === null) {
        localStorage.setItem('signUpType', 'purchase')
        this.$bvModal.show('signup')
      } else {
        this.loading = true
        ApiService.shoppingBasketPost(this.task.hash, this.task.packages[0].hash, this.quantitySelected).then((data) => {
          this.googleTag('eec.add')

          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'selectPurchase'
            })
          }
          if (this.$route.params.queryID) {
            this.$router.push('/checkout/' + data.data.data.hash + '/pay/' + this.$route.params.queryID)
          } else {
            this.$router.push('/checkout/' + data.data.data.hash + '/pay')
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            this.taskRemove()
          } else if (error.response.status === 400) {
            this.loading = false
            this.errorMessageSwitch = true
            this.errorMessage = error.response.data.error_message
          }
        })
      }
    },
    taskRemove () {
      ApiService.shoppingBasketGet().then((data) => {
        ApiService.shoppingBasketDelete(data.data.data[0].hash).then((data) => {
          this.taskBasket()
        }).catch(() => { })
      }).catch(() => { })
    },
    confirmActivateTask () {
      this.$swal({
        title: this.$t('sure'),
        text: this.active ? this.$t('deactivate_warning') : this.$t('activate_warning'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.active ? this.$t('deactivate_action') : this.$t('activate_action'),
        cancelButtonText: this.$t('discard_action'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise(() => {
            let data = {
              'status': !this.active ? 2 : 0
            }
            ApiService.gigActivation(this.slug, data).then(response => {
              this.active = !this.active
              this.task_status = this.active ? 2 : 0
              this.$swal({
                title: this.active ? this.$t('submitted_for_review') : this.$t('deactivated'),
                text: this.active ? this.$t('activate_confirm') : this.$t('deactivate_confirm'),
                type: 'success',
                confirmButtonText: this.$t('close_button')
              }).then((result) => {
                this.$router.push('/my-account/tasks')
              })
            }).catch((error) => {
              this.$swal.showValidationMessage(
                '<p>' + error.response.data.error_message + '</p>'
              )
              this.$swal.hideLoading()
            })
          })
        }
      })
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    },
    getDisplayPrice (price) {
      return helper.getDisplayPrice(price)
    },
    checkValidQuantity () {
      if (this.quantitySelected === '' || this.quantitySelected < 1) {
        this.quantitySelected = 1
      } else if (this.quantitySelected > this.maxQuantity) {
        this.quantitySelected = this.maxQuantity
      } else if (!Number.isInteger(this.quantitySelected)) {
        this.quantitySelected = Math.floor(this.quantitySelected)
      }
    },
    quantityAdd () {
      if (this.quantitySelected < this.maxQuantity) {
        this.quantitySelected = Number(this.quantitySelected) + 1
      }
    }
  },
  watch: {
    quantitySelected: function (val) {
      this.quantityMaxReached = val > this.maxQuantity
      this.quantityMinReached = val < this.minQuantity || val === 0
      if (this.quantityMaxReached) {
        this.quantityErrorMessage = this.$t('order_max_quantity_error', { quantity: this.maxQuantity.toFixed(0) })
        this.quantityErrorMessageSwitch = true
      } else if (this.quantityMinReached) {
        this.quantityErrorMessage = this.$t('order_min_quantity_error')
        this.quantityErrorMessageSwitch = true
      } else if (!Number.isInteger(Number(val))) {
        this.quantityErrorMessage = this.$t('order_invalid_quantity_error')
        this.quantityErrorMessageSwitch = true
      } else {
        this.quantityErrorMessageSwitch = false
        this.quantityErrorMessage = ''
      }
    },
    signUpComplete: function (newval, oldval) {
      if (newval[0] === 'purchase') {
        localStorage.removeItem('signUpType')
        this.taskBasket()
      } else if (newval[0] === 'custom-quote') {
        this.customTaskBasket()
      }
      this.$store.state.signUpErrors = []
    },
    trackingDataProperty: function (newVal, oldVal) {
      if (Object.keys(newVal[0]).length > 1 && newVal[1] === true) {
        this.googleTag('eec.detail')
      }
    }
  },
  computed: {
    ...mapState({ signUpComplete: state => state.register.signUpComplete }),
    isLoggedIn () {
      return this.$store.state.accessToken !== null
    },
    getCalculatedPrice () {
      return this.settings.service_rate ? (this.task.packages[0].price + (this.task.packages[0].price * this.settings.service_rate / 100)) * this.quantitySelected : this.task.packages[0].price * this.quantitySelected
    },
    trackingDataProperty: function () {
      return [this.task, this.statsLoaded]
    },
    displayPurchase () {
      if (this.$store.getters.hasRole('ROLE_ADMIN') && this.task.user.verification_type === 2) {
        return false
      }
      return this.task.user.username !== this.username && !this.away
    },
    isJob () {
      return this.task.job
    }
  }

}
</script>

<style lang="scss">

  /// TODO - unpick this mess
  .task-info .profile-description .card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    ul {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-top: 10px !important;
      padding-bottom: 8px !important;
    }
  }

  .task-heading {
    font-size: 2rem;
  }

  .task-info + footer + a {
    display: none;
  }
  #custom-quote___BV_modal_body_ {
    padding: 1rem;
    p {
      font-size: 14px!important;
    }
  }
  #custom-quote___BV_modal_title_ {
    font-weight: bold;
    text-align: left;
  }

  @include md-up {
    .task-info + footer + a {
      display: block;
    }
    /* ONLY FOR CUSTOM TASK */
    #custom-quote___BV_modal_outer_ .modal .modal-dialog {
      max-width: 530px!important;
    }
  }

</style>

<style lang="scss" scoped>

  .carousel-holder {
    border: $light-border;
  }

  h3 {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 40%;
      bottom: 0;
      background: $task-light-grey;
      left: 0;
      margin: 0 30%;
    }
  }

  .title {
    span {
      display: inline-block;
      li {
        float: left;
        padding-left: 2px;
        color: #62646a !important;
        i {
          color: #ffbf00;
        }
      }
    }
  }

  span {
    list-style-type: none;
    font-size: 14px;
  }

  .list li {
    display: block;
    text-align: left;
  }
  .alert {
    font-size: 1rem;
    float: left;
    clear: left;
  }

  p.description {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .custom-quote.btn {
    background-color: $brand-navy;
    border-color: $brand-navy;
  }

  .task-top-info {
    background: $task-grey;
    border-top: $light-border;
    border-left: $light-border;
    border-right: $light-border;
    font-size: 14px;
    li {
      display: inline-block;
      i {
        color: #ffbf00;
      }
    }
  }

  .buy-cta-mobile {
    bottom: 0;
    left: 0;
    height: auto;
    z-index: 999;
    width: 100%;
    background: white;
    position: fixed;
    box-shadow: 0px 0 20px 0px #aaa;
    .price-mobile {
      font-size: 12px;
    }
  }

  .sidebar-profile {
    overflow-x: hidden;
    z-index: 1;
  }

  .sidebar-summary {
    h3 {
      &:after {
        width: 0;
        height: 0;
      }
    }
    .card {
      padding: 1.25rem;
      .summary-info {
        font-size: 14px;
        color: $text-grey;
        .stat {
          border-bottom: 1px solid $task-light-grey;
          &.languages {
            border: 0;
          }
          .icon {
            width: 30px;
            height: 30px;
            background: $task-light-grey;
            border-radius: 8px;
            color: white;
            font-size: 1.25rem;
          }
          .amount {
            font-size: 1.25rem;
            color: $brand-denim;
          }
        }
        .language-available {
          margin-left: 75px;
          font-size: .75rem;
        }
      }
    }
    input {
      width: 45px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance:textfield; /* Firefox */
      }
    }
  }

  .social-links {
    .fa-heart {
      color: #000;
      opacity: 0.3;
      transition: all .2s;
      font-size: 1.2em;
      text-shadow: $card-action-shadow;
      &:hover {
        opacity: 0.6;
        color: $brand-scarlet;
        transition: all .2s;
      }
      &.save-toggle {
        color: $brand-scarlet;
        opacity: 0.8;
      }
    }
    .fa-share-nodes, .fa-flag {
      color: #000;
      opacity: 0.3;
      cursor: pointer;
      margin-left: 12px;
      font-size: 1.2em;
      &:hover {
        color: #44aae1 !important;
        opacity: 1;
      }
    }
  }

  @include md-up {
    .w-md-auto {
      width: auto !important;
    }
  }
</style>
